import React from 'react';

const Select = ({ label, options, ...props }) => {
  return <div className="form-group">
    {label && <label htmlFor={props.name} className="text-nowrap">{label}</label>}
    <select className="custom-select shipping-item-inquiry-offer shipping-item-input" {...props}>
      {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  </div>
}

export default Select
