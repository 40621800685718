import React, { useState, useRef, useContext } from 'react'

export const context = React.createContext(null)

import SalesUnit from './PriceRangesForm/SalesUnit'
import PriceRange from './PriceRangesForm/PriceRange'

const ContextProvider = ({ offerId, productType, singlePower, text, images, groupSeparator, decimalSeparator, children }) => {
  return <context.Provider value={{offerId, productType, singlePower, text, images, groupSeparator, decimalSeparator}}>
    {children}
  </context.Provider>
}

function initRanges(priceRanges, indexCount) {
  const ranges = priceRanges ? JSON.parse(priceRanges) : [];

  if(ranges.length > 0) {
    return ranges.map(r => ({...r, index: indexCount.current++}))
  }

  return [{id: null, index: indexCount.current++, quantityFrom: '', quantityTo: '', pricePerQuantity: '', errors: []}]
}

function validateRange(range) {
  const errors = {}

  const quantityFrom = parseInt(range.quantity_from)
  const quantityTo = parseInt(range.quantity_to)

  if(quantityFrom && quantityTo) {
    if(quantityFrom > quantityTo) {
      errors['quantity_to'] = true
    }
  }

  if(range.price_per_quantity && range.price_per_quantity !== '') {
    const pricePerQuantity = parseFloat(range.price_per_quantity.replace(',', '.'))

    if(isNaN(pricePerQuantity) || pricePerQuantity === 0 || pricePerQuantity < 0) {
      errors['price_per_quantity'] = true
    }
  }

  return errors
}

const DestroyRange = ({ priceRange }) => {
  return <>
    <input type='hidden' name={`offer[price_ranges_attributes][${priceRange.index}][_destroy]`} value='1' />
    <input type='hidden' name={`offer[price_ranges_attributes][${priceRange.index}][id]`} value={priceRange.id} />
  </>
}

const MinVolumeInfo = ({ priceRanges, salesUnit }) => {
  const { text } = useContext(context)

  const minQuantity = priceRanges[0].quantity_from

  if(minQuantity && salesUnit.volumeType === 'per_pallet' && salesUnit.palletSize > 0) {
    const info = text.sales_unit.min_order_pallets.replace('%{volume}', minQuantity).replace('%{quantity}', minQuantity * salesUnit.palletSize)
    return <div dangerouslySetInnerHTML={{__html: info}} />
  }

  if(minQuantity && salesUnit.volumeType === 'per_container') {
    const info = text.sales_unit.min_order_containers.replace('%{volume}', minQuantity).replace('%{quantity}', minQuantity * salesUnit.containerSize)
    return <div dangerouslySetInnerHTML={{__html: info}} />
  }

  return null
}

const PriceRangesForm = ({ offerId, priceRanges, productType, singlePower, palletSize, containerSize, volumeType, errors, text, images, groupSeparator, decimalSeparator }) => {
  const indexCount = useRef(0)
  const [ ranges, setRanges ] = useState(() => initRanges(priceRanges, indexCount))
  const [ removedRanges, setRemovedRanges ] = useState([])
  const [ salesUnit, setSalesUnit ] = useState({volumeType, palletSize, containerSize})

  function updateSalesUnit(data) {
    if(data.hasOwnProperty('volumeType') && data.volumeType !== salesUnit.volumeType) {
      setSalesUnit(su => ({...su, ...data}))
      const rangesToRemove = ranges.filter(range => !!range.id)
      setRemovedRanges(r => [...r, ...rangesToRemove])
      setRanges(initRanges(null, indexCount))

      return
    }

    if(data.hasOwnProperty('palletSize') && data.palletSize !== salesUnit.palletSize && salesUnit.volumeType === 'per_pallet') {
      setSalesUnit(su => ({...su, ...data}))
      const rangesToRemove = ranges.filter(range => !!range.id)
      setRemovedRanges(r => [...r, ...rangesToRemove])
      setRanges(initRanges(null, indexCount))

      return
    }

    if(data.hasOwnProperty('containerSize') && data.containerSize !== salesUnit.containerSize && salesUnit.volumeType === 'per_container') {
      setSalesUnit(su => ({...su, ...data}))
      const rangesToRemove = ranges.filter(range => !!range.id)
      setRemovedRanges(r => [...r, ...rangesToRemove])
      setRanges(initRanges(null, indexCount))

      return
    }

    setSalesUnit(su => ({...su, ...data}))
  }

  function addRange() {
    const lastRange = ranges[ranges.length - 1]
    const quantityTo = parseInt(lastRange.quantity_to)
    const quantity_from = quantityTo ? quantityTo + 1 : ''

    const newRange = {
      id: null,
      index: indexCount.current++,
      quantity_from,
      quantity_to: '',
      price_per_quantity: '',
      errors: []
    }

    setRanges([
      ...ranges,
      newRange
    ])
  }

  function setRange(index, range) {
    const rangeIdx = ranges.findIndex(r => r.index === index)
    const followingRangeIndex = ranges[rangeIdx + 1] ? ranges[rangeIdx + 1].index : null

    setRanges(ranges.map(r => {
      if(r.index === index) {
        return { ...r, ...range, errors: validateRange(range, ranges)}
      }

      if(followingRangeIndex && r.index === followingRangeIndex) {
        const quantityFrom = parseInt(range.quantity_to)
        const quantity_from = quantityFrom ? quantityFrom + 1 : ''

        return { ...r, quantity_from }
      }

      return r
    }))
  }

  function removeRange(range) {
    setRanges(r => {
      const rangeIdx = r.findIndex(r => r.index === range.index)
      const filtered = r.toSpliced(rangeIdx, 1)

      if(rangeIdx !== 0 && filtered[rangeIdx]) {
        const quantityTo = parseInt(filtered[rangeIdx - 1].quantity_to)
        const quantity_from = quantityTo ? quantityTo + 1 : ''

        filtered[rangeIdx].quantity_from = quantity_from
      }

      return filtered
    })

    if(range.id) setRemovedRanges(rm => [...rm, range])
  }

  const quantityUnit = text['common']['units']['piece_short']
  const priceUnit = productType === 'SolarModule' ? '€/Wp' : `€/${quantityUnit}`

  let icon
  if(salesUnit.volumeType === 'per_pallet') icon = images['icon_pallets']
  if(salesUnit.volumeType === 'per_container') icon = images['icon_container']

  return <ContextProvider offerId={offerId} text={text} images={images} groupSeparator={groupSeparator} decimalSeparator={decimalSeparator} singlePower={singlePower} productType={productType}>
    <div className='section-title'>
      {text['header']['sales_unit']}
    </div>
    <SalesUnit salesUnit={salesUnit} updateSalesUnit={updateSalesUnit} />
    <hr className='mb-5' />
    <div className='section-title'>
      {text['header']['price_ranges']}
    </div>
    <div className='row align-items-center'>
      <div className='col-sm-2'>
        <label data-required="true">{text["offer_price_range"]["quantity_from"]}</label>
      </div>
      <div className='col-sm-2'>
        <label data-required="true">{text["offer_price_range"]["quantity_to"]}</label>
      </div>
      <div className='col-sm-2'>
        <label data-required="true">{text["offer_price_range"]["price_per_quantity"]}</label>
      </div>
      <div className="col-sm-1"></div>
      <div className="col-sm-5 text-center">
        <label>{text["offer_price_range"]["price_per_quantity_including_fee"]}</label> <b>{priceUnit}</b>
      </div>
    </div>
    {removedRanges.map(range => <DestroyRange priceRange={range} key={range.id} />)}
    {ranges.map((range, i) => <PriceRange priceRanges={ranges} priceRange={range} salesUnit={salesUnit} key={range.index} i={i} addRange={addRange} setRange={setRange} removeRange={removeRange} quantityUnit={quantityUnit} priceUnit={priceUnit} icon={icon} />)}
    <MinVolumeInfo priceRanges={ranges} salesUnit={salesUnit} />
  </ContextProvider>
}

export default PriceRangesForm
