import React, { useContext } from 'react'

import { context } from '../ShippingItemsIndex'

import Input from './Input'

const PiecesInput = ({ item, shippingItemProduct, label }) => {
  const { products, defaultSizes, setItems } = useContext(context)

  const handleChange = e => {
    let newWeight = NaN

    for(const sip of item.shipping_item_products) {
      if(sip.id !== shippingItemProduct.id) {
        const product = products.find(p => p.inquiry_offer_id === sip.inquiry_offer_id)

        if(product) {
          const pieces = sip.quantity.value || 0
          const weight = product.weight || 0

          if(isNaN(newWeight)) newWeight = 0
          newWeight += (weight * pieces)
        }
      }
    }

    const pieces = parseInt(e.target.value)
    const product = products.find(p => p.inquiry_offer_id === shippingItemProduct.inquiry_offer_id)
    const packageType = defaultSizes[item.package_type.value]

    if(pieces > 0) {
      if(product.weight) {
        if(isNaN(newWeight)) newWeight = 0
        newWeight += (product.weight * pieces)
      }

      if(packageType.weight) {
        if(isNaN(newWeight)) newWeight = 0
        newWeight += packageType.weight
      }
    }

    setItems(state => {
      return state.map(it => {
        if(it.id !== item.id) return it

        return {
          ...it,
          weight: { value: newWeight, valid: !isNaN(newWeight) },
          shipping_item_products: it.shipping_item_products.map(sip => {
            if(sip.id !== shippingItemProduct.id) return sip
            return { ...sip, quantity: { value: pieces, valid: !isNaN(pieces) } }
          })
        }
      })
    })
  }

  return <Input type="number" label={label} value={shippingItemProduct.quantity.value} onChange={handleChange} name="pieces" min="1" valid={shippingItemProduct.quantity.valid} />
}

export default PiecesInput
