import React from 'react'

import Input from './Input'

function isValid(value, required = false, format = null) {
  if(required && value === '') return false
  if(format && !format.test(value)) return false

  const parsed = parseInt(value)

  if(isNaN(parsed)) return false
  if(parsed <= 0) return false

  return true
}

const NumberInput = ({ item, update, name, label, min = 1, unit, format, className, required = true, decimal = false }) => {
  const setValue = value => {
    const valid = decimal ? isDecimalValid(value, decimalSeparator, required) : isValid(value, required, format)

    update(name, value, valid)
  }

  return <Input type="number" label={label} unit={unit} value={item[name].value} setValue={setValue} name={name} min={min} className={className} valid={item[name].valid} />
}

export default NumberInput
