import React from 'react'

const Checkbox = ({ item, update, name }) => {
  const handleChange = e => {
    update(name, e.target.checked, true)
  }

  return <input type="checkbox" name="stackable" checked={item[name].value} onChange={handleChange} className="form-check-input shipping-item-stackable shipping-item-input" />
}

export default Checkbox
