import React, { useContext } from 'react'
import CurrencyInput from 'react-currency-input-field'

import { context } from '../ShippingItemsIndex'

const InputWithPrecision = ({ setValue, label, unit, className, valid = true, ...props }) => {
  const { groupSeparator, decimalSeparator } = useContext(context)

  let klassName = 'form-control shipping-item-quantity shipping-item-input'
  if(className) klassName += ` ${className}`
  if(!valid) klassName += ' is-invalid'

  const handleChange = (value, name, values) => {
    setValue(value || "")
  }

  const input = <CurrencyInput className={klassName} onValueChange={handleChange} disableGroupSeparators={true} groupSeparator={groupSeparator} decimalSeparator={decimalSeparator} allowDecimals={true} allowNegativeValue={false} decimalsLimit={2} {...props} />
  let wrapper

  if(unit) {
    wrapper = <div className="input-group flex-nowrap">
      {input}
      <div className="input-group-append">
        <div className="input-group-text">{unit}</div>
      </div>
    </div>
  } else {
    wrapper = input
  }

  return <div className="form-group">
    {label && <label htmlFor={name}>{label}</label>}
    {wrapper}
  </div>
}

export default InputWithPrecision
