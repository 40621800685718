import React, { useState } from 'react'
import { Table } from './ShippingRatesTables/Table'
import {
  createTable as apiCreateTable,
  updateTable as apiUpdateTable,
} from '@/api/shipping_rates'

const ShippingRatesTables = ({ shippingRatesTables = [], userStatus }) => {
  const isLoggedIn = !!userStatus
  const [tables, setTables] = useState(shippingRatesTables.length ? shippingRatesTables : [{ shippingRates: [] }])
  const [showNotice, setShowNotice] = useState(false)
  const [noticeType, setNoticeType] = useState(null)
  const [noticeText, setNoticeText] = useState(null)

  const setNotice = (type, text) => {
    setNoticeType(type)
    setNoticeText(text)
    setShowNotice(true)
  }

  const handleSetTables = (table, tableIndex) => {
    let tablesCopy = [...tables]
    tablesCopy[tableIndex] = table
    setTables(tablesCopy)
  }

  const saveTable = (table, tableIndex) => {
    if (table.id) {
      apiUpdateTable({
        id: table.id,
        shipping_rates_attributes: table.shippingRates
      }).then((updatedTable) => {
        handleSetTables(updatedTable, tableIndex)
        setNotice('alert-info', 'Table updated succesfully!')
      })
    } else {
      apiCreateTable({
        shipping_rates_attributes: table.shippingRates
      }).then((newTable) => {
        handleSetTables(newTable, tableIndex)
        setNotice('alert-info', 'Table created succesfully!')
      })
    }
  }

  return <>
    {showNotice && (
      <div className={`alert notice ${noticeType}`}>
        {noticeText}
        <i className='fa-solid fa-xmark' onClick={() => setShowNotice(false)}></i>
      </div>
    )}
    {tables.map((table, tableIndex) => {
      return (
        <Table
          table={table}
          tableIndex={tableIndex}
          saveTable={saveTable}
          setNotice={setNotice}
        />
      )
    })}
  </>
}

export default ShippingRatesTables
