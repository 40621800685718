import React from 'react'

const Input = ({ setValue, label, unit, className, valid = true, ...props }) => {
  let klassName = 'form-control shipping-item-quantity shipping-item-input'
  if(className) klassName += ` ${className}`
  if(!valid) klassName += ' is-invalid'

  const handleChange = e => {
    setValue(e.target.value)
  }

  const input = <input className={klassName} onChange={handleChange} {...props} />
  let wrapper

  if(unit) {
    wrapper = <div className="input-group flex-nowrap">
      {input}
      <div className="input-group-append">
        <div className="input-group-text">{unit}</div>
      </div>
    </div>
  } else {
    wrapper = input
  }

  return <div className="form-group">
    {label && <label htmlFor={name}>{label}</label>}
    {wrapper}
  </div>
}

export default Input
