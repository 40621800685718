import React, { useState, useId } from 'react'
import I18n from '@/src/i18n.js.erb'
import { default as Select, components } from 'react-select'

export const CountryCodes = ({
  countries,
  setCountries,
  countryScope = false,
  setCountryScope,
  highlightErrors = false,
}) => {
  const countryCodes = countries?.split(',') || []
  const euCountriesList = ["AT", 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE']
  const countriesList = Object.keys(I18n.t('frontend.countries')).filter((key) => {
    return euCountriesList.includes(key)
  })
  const [isSwitchOn, setIsSwitchOn] = useState(countryScope)
  const handleToggle = () => {
    setCountryScope(!isSwitchOn)
    setIsSwitchOn(!isSwitchOn)
  }

  const OptionWithCheckbox = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const Switch = ({ isOn, handleToggle, label }) => {
    const id = useId()
    return (
      <div className="switch-wrapper">
        <span className="switch-container">
          <input
            checked={isOn}
            onChange={handleToggle}
            className="switch-checkbox"
            id={id}
            type="checkbox"
          />
          <label
            className="switch-slider"
            htmlFor={id}
          >
            <span className={`switch-button`} />
          </label>
        </span>
        <label className="switch-label">{label}</label>
      </div>
    );
  };

  const MenuListWithToggle = (props) => (
    <components.MenuList {...props}>
      <div style={{ padding: '8px' }}>
        <Switch isOn={isSwitchOn} handleToggle={handleToggle} label='Include/exclude' />
      </div>
      {props.children}
    </components.MenuList>
  );

  const getCountriesOptions = (options) => {
    return options.map((country) => { return { value: country, label: I18n.t('frontend.countries')[country] } })
  }

  const handleSetCountries = (options) => {
    setCountries(options.length ? options.map((o) => o.value).join(',') : null)
  }

  return (
    <Select
      isMulti
      options={getCountriesOptions(countriesList)}
      value={getCountriesOptions(countryCodes)}
      onChange={handleSetCountries}
      components={{
        Option: OptionWithCheckbox,
        MenuList: MenuListWithToggle
      }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      className={highlightErrors && !countryCodes.length ? 'error' : ''}
      placeholder='select country...'
      styles={{
        control: (base) => ({
          ...base,
          border: '0px',
          boxShadow: 'none'
        }),
        dropdownIndicator: (base) => ({
          ...base,
          display: 'none'
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none'
        }),
      }}
    />
  )
}
