import React, { useContext } from 'react'
import { context } from '../ShippingItemsIndex'

function dimensionsPresent(product) {
  return product.length && product.width && product.height && product.weight;
}

function pu1Present(product) {
  return product.packaging_type && product.packaging_unit && product.box_length && product.box_width && product.box_height;
}

function pu2Present(product) {
  return product.packaging_type2 && product.packaging_unit2 && product.box_length2 && product.box_width2 && product.box_height2;
}

const Dimensions = ({ product }) => {
  let dimensions = ''

  if(product['type'] === 'solar_module') {
    dimensions = `${product.length} x ${product.width} x ${product.thickness} (mm)`
  } else {
    dimensions = `${product.length} x ${product.width} x ${product.height} (mm)`
  }

  if(product.weight) {
    dimensions += ` / ${product.weight} kg`
  }

  return <div>
    Dimensions: {dimensions}
  </div>
}

const Pu1 = ({ product }) => {
  return <div>
    PU1 {product.packaging_type}: {product.packaging_unit} / {product.box_length} x {product.box_width} x {product.box_height} (cm)
  </div>
}

const Pu2 = ({ product }) => {
  return <div>
    PU2: {product.packaging_type2}: {product.packaging_unit2} / {product.box_length2} x {product.box_width2} x {product.box_height2} (cm)
  </div>
}

const Product = ({ items, product }) => {
  let totalPacked = 0

  for(const item of items) {
    for(const sip of item.shipping_item_products) {
      if(sip.inquiry_offer_id === product.inquiry_offer_id) {
        const quantity = parseInt(item.quantity.value) || 0
        const pieces = parseInt(sip.quantity.value) || 0

        totalPacked += quantity * pieces
      }
    }
  }

  return <div className="row">
    <div className="col col-md-3">
      <a href={product.edit_path} target="_blank">{product.name}</a>
    </div>
    <div className="col col-md-9">
      No. of pieces: {product.quantity}, Packed: {totalPacked}<br />
      {dimensionsPresent(product) && <Dimensions product={product} />}
      {pu1Present(product) && <Pu1 product={product} />}
      {pu2Present(product) && <Pu2 product={product} />}
      {product.no_groupage === false && <i>No groupage </i>}
      {product.dangerous_goods && <i>Dangerous goods</i>}
    </div>
  </div>
}

const ProductInformation = ({ items }) => {
  const { products } = useContext(context)

  return products.map((product, index) => {
    return <div key={product.inquiry_offer_id}>
      <Product items={items} product={product} />
      {index <= products.length - 2 && <hr />}
    </div>
  })
}

export default ProductInformation
