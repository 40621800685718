import React, { useContext } from 'react'

import { context } from '../ShippingItemsIndex'

import Select from './Select'

const PackageTypeSelect = ({ item }) => {
  const { products, defaultSizes, setItems } = useContext(context)

  const name = 'package_type'
  const packageOptions = []

  for(const size of Object.values(defaultSizes)) {
    packageOptions.push({ value: size.name, label: size.name })
  }

  const handleChange = e => {
    const currentPackageType = defaultSizes[item.package_type.value]
    const newPackageType = defaultSizes[e.target.value]
    const newPackageTypeWeight = newPackageType.weight || 0

    let newWeight = 0
    for(const shippingItemProduct of item.shipping_item_products) {
      const product = products.find(p => p.inquiry_offer_id === shippingItemProduct.inquiry_offer_id)

      if(product) {
        const pieces = shippingItemProduct.quantity.value || 0
        const weight = product.weight || 0
        newWeight += (weight * pieces)
      }
    }

    newWeight += newPackageTypeWeight

    const newLength = newPackageType.length ? {value: newPackageType.length, valid: true} : item.length
    const newWidth = newPackageType.width ? {value: newPackageType.width, valid: true} : item.width

    let newHeight

    if(newPackageType.height && currentPackageType.height === parseInt(item.height.value)) {
      newHeight = {value: newPackageType.height, valid: true}
    } else {
      newHeight = item.height
    }

    setItems(state => {
      return state.map(it => {
        if(it.id === item.id) {
          return {
            ...it,
            package_type: { value: e.target.value, valid: true },
            weight: { value: newWeight, valid: true },
            length: newLength,
            width: newWidth,
            height: newHeight,
          }
        }

        return it
      })
    })
  }

  return <Select name={name} value={item[name].value} onChange={handleChange} label="Package" options={packageOptions} />
}

export default PackageTypeSelect
