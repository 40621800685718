import React, { useState, useEffect } from 'react'

import { CountryCodes } from './CountryCodes'
import { Weight } from './Weight'
import { MaxDimensions } from './MaxDimensions'
import { LongestSide } from './LongestSide'
import { TableInput } from './TableInput'
import { CarrierName } from './CarrierName'

export const Row = ({
  shippingRate,
  rowIndex,
  saveRow,
  duplicateRow,
  deleteRow,
  highlightErrors,
}) => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setShowError(highlightErrors)
  }, [highlightErrors])

  const handleSaveRow = (newRow) => {
    saveRow(rowIndex, newRow)
  }

  return (
    <tr>
      <td className='country-codes'>
        <CountryCodes
          countries={shippingRate.country_codes}
          setCountries={(value) => handleSaveRow({...shippingRate, country_codes: value})}
          countryScope={shippingRate.country_scope}
          setCountryScope={(value) => handleSaveRow({...shippingRate, country_scope: value})}
          highlightErrors={showError}
          required
        />
      </td>
      <td className='weight'>
        <Weight
          value={shippingRate.weight}
          onValueChange={(value) => handleSaveRow({...shippingRate, weight: value})}
          highlightErrors={showError}
          required
        />
      </td>
      <td className='max-dimensions'>
        <MaxDimensions
          row={shippingRate}
          setRow={handleSaveRow}
        />
      </td>
      <td className='longest-side'>
        <LongestSide
          value={shippingRate.longest_side}
          onValueChange={(value) => handleSaveRow({...shippingRate, longest_side: value})}
        />
      </td>
      <td className='freight-cost'>
        <TableInput
          value={shippingRate.freight_cost_amount}
          onValueChange={(value) => handleSaveRow({...shippingRate, freight_cost_amount: value})}
          placeholder='enter freight costs'
          suffix={'€'}
          highlightErrors={showError}
          required
        />
      </td>
      <td className='free-shipping'>
        <TableInput
          value={shippingRate.free_shipping_threshold}
          onValueChange={(value) => handleSaveRow({...shippingRate, free_shipping_threshold: value})}
          placeholder='set order value'
          suffix={'€'}
        />
      </td>
      <td className='max-insurance'>
        <TableInput
          value={shippingRate.max_insurance_value}
          onValueChange={(value) => handleSaveRow({...shippingRate, max_insurance_value: value})}
          placeholder='set max. value'
          suffix={'€'}
        />
      </td>
      <td className='carrier-name'>
        <CarrierName
          value={shippingRate.carrier_name}
          onValueChange={(value) => handleSaveRow({...shippingRate, carrier_name: value})}
        />
      </td>

      <td className='actions'>
        <>
          <button className='btn' onClick={duplicateRow}>
            <i className="fa-solid fa-copy"></i>
          </button>
          <button className='btn' onClick={deleteRow}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </>
      </td>
    </tr>
  )
}
