import React, { useState } from 'react'
import I18n from '@/src/i18n.js.erb'

import { Row } from './Row'

export const Table = ({
  table,
  tableIndex,
  saveTable,
  setNotice,
}) => {
  const headers = [
    'country_codes',
    'weight',
    'max_dimensions',
    'longest_side',
    'freight_cost_amount',
    'free_shipping_threshold',
    'max_insurance_value',
    'carrier_name',
  ]

  const [shippingRates, setShippingRates] = useState(table.shippingRates)
  const [highlightErrors, setHighlightErrors] = useState(false)

  const handleAddRow = () => {
    setShippingRates([...shippingRates, {}])
  }

  const saveRow = (rowIndex, row) => {
    let shippingRatesCopy = [...shippingRates]
    shippingRatesCopy[rowIndex] = row

    setShippingRates(shippingRatesCopy)
  }

  const duplicateRow = (rowIndex) => {
    const shippingRatesCopy = [...shippingRates]
    const row = { ...shippingRatesCopy[rowIndex], id: null }
    shippingRatesCopy.push(row)

    setShippingRates(shippingRatesCopy)
  }

  const deleteRow = (rowIndex) => {
    let shippingRatesCopy = [...shippingRates]
    let row = shippingRatesCopy[rowIndex]

    if (row.id) {
      shippingRatesCopy[rowIndex]['_destroy'] = 1
    } else {
      shippingRatesCopy = shippingRatesCopy.filter((_, index) => index != rowIndex)
    }

    setShippingRates(shippingRatesCopy)
  }

  const validateTable = () => {
    return shippingRates.filter((shippingRate) => {
      return !shippingRate.country_codes || !shippingRate.weight || !shippingRate.freight_cost_amount
    }).length == 0
  }

  const handleSaveTable = () => {
    setHighlightErrors(false)

    if(validateTable()) {
      saveTable({...table, shippingRates: shippingRates}, tableIndex)
    } else {
      setNotice('alert-warning', 'Some rows are not valid')
      setHighlightErrors(true)
    }
  }

  return <>
    <table className='table'>
      <thead>
        <tr>
          {headers.map((key) => {
            return <th>{I18n.t(`frontend.shipping_rates.table.${key}.header`)}</th>
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {shippingRates.map((shippingRate, rowIndex) => {
          if (!shippingRate['_destroy']) {
            return (
              <Row
                shippingRate={shippingRate}
                tableIndex={tableIndex}
                rowIndex={rowIndex}
                saveRow={saveRow}
                duplicateRow={() => duplicateRow(rowIndex)}
                deleteRow={() => deleteRow(rowIndex)}
                highlightErrors={highlightErrors}
              />
            )
          }
        })}
      </tbody>
    </table>

    <div className='table-actions'>
      <button className='btn btn-primary' onClick={handleSaveTable}>
        Save table
      </button>
      <button className='btn btn-secondary' onClick={handleAddRow}>
        Add row
      </button>
    </div>
  </>
}
