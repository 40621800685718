const baseUrl = '/api/v1'

const getHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  return headers
}

const createTable = async (data) => {
  const url = `${baseUrl}/shipping_rates`
  const params = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data)
  }
  const apiCall = await fetch(url, params)
  return await apiCall.json()
}

const updateTable = async (data) => {
  const url = `${baseUrl}/shipping_rates/${data.id}`
  const params = {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(data)
  }
  const apiCall = await fetch(url, params)
  return await apiCall.json()
}

const deleteTable = async (data) => {
  const url = `${baseUrl}/shipping_rates/${data.id}`
  return await fetch(url, { method: 'DELETE' })
}

export { createTable, updateTable, deleteTable }
