import React, { useContext } from 'react'

import { context } from '../ShippingItemsIndex'

import ShippingItemProduct from './ShippingItemProduct'

const ShippingItemProducts = ({ item }) => {
  const { products, setItems, lastInsertId } = useContext(context)

  const product = products[0]
  const pieces = 1
  let inquiryOfferId = ''
  let weightToAdd = 0

  if(product) {
    inquiryOfferId = product.inquiry_offer_id

    if(product.weight) weightToAdd += product.weight * pieces
  }

  const handleAdd = e => {
    e.preventDefault()

    setItems(state => {
      return state.map(it => {
        if(it.id !== item.id) return it

        return {
          ...it,
          weight: { value: it.weight.value + weightToAdd, valid: true },
          shipping_item_products: [...it.shipping_item_products, {id: `new-${lastInsertId.current++}`, quantity: {value: pieces, valid: true}, inquiry_offer_id: inquiryOfferId}]
        }
      })
    })
  }

  return <div className="col col-md-4 shipping-item-products">
    <div className="row">
      <div className="col col-md-3">
        <label>Pieces</label>
      </div>
      <div className="col col-md-8">
        <label>Product</label>
      </div>
    </div>
    <div className="shipping-item-products__items">
      {item.shipping_item_products.map((shippingItemProduct) => <ShippingItemProduct key={shippingItemProduct.id} item={item} shippingItemProduct={shippingItemProduct} />)}
    </div>
    <div className="row">
      <div className="col col-md-11 shipping-item-products__actions">
        <button className="btn btn-primary-light btn-sm" onClick={handleAdd}>Add product</button>
      </div>
    </div>
  </div>
}

export default ShippingItemProducts
