import React, { useContext } from 'react'

import XSquareIcon from '../common/XSquareIcon'
import StackIcon from '../common/StackIcon'

import { context } from '../ShippingItemsIndex'

import Checkbox from './Checkbox'
import ShippingItemProducts from './ShippingItemProducts'
import PackageTypeSelect from './PackageTypeSelect'
import NumberInput from './NumberInput'
import DecimalInput from './DecimalInput'
import MeasurementsInput from './MeasurementsInput'

const ShippingItem = ({ item }) => {
  const { setItems } = useContext(context)

  const handleRemove = e => {
    e.preventDefault()

    setItems(state => state.filter(it => it.id !== item.id))
  }

  const update = (name, value, valid) => {
    setItems(state => {
      return state.map(it => {
        if(it.id === item.id) {
          return { ...it, [name]: { value, valid } }
        }

        return it
      })
    })
  }

  return <div className="row">
    <div className="col col-md-1">
      <NumberInput item={item} update={update} name="quantity" label="Collies" />
    </div>

    <div className="col col-md-2">
      <PackageTypeSelect item={item} />
    </div>

    <div className="col col-md-4">
      <div className="row">
        <div className="col col-md-7">
          <MeasurementsInput item={item} update={update} />
        </div>

        <div className="col col-md-5">
          <DecimalInput item={item} update={update} name="weight" label="Weight" unit="kg" className="ml-1" />
        </div>
      </div>
    </div>

    <ShippingItemProducts item={item} />

    <div className="col col-md-1 shipping-items__item-actions">
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="form-check form-check-inline flex-gap-2">
          <Checkbox item={item} update={update} name="stackable" />
          <StackIcon />
          <XSquareIcon className="clickable" onClick={handleRemove} />
        </div>
      </div>
    </div>
  </div>
}

export default ShippingItem
