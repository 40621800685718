import React from 'react'

import I18n from '@/src/i18n.js.erb'

import { starConfigurationToDisplay } from '../helpers'

import { StarFilled, StarHalfFilled, StarEmpty } from './Icons';

const OfferRating = ({ offer }) => {
  const { fullStars, halfStar, emptyStars } = starConfigurationToDisplay(offer.vendor_rating)

  return <div className='d-flex'>
    <div className='stars'>
      {[...Array(fullStars)].map((_, i) => <StarFilled key={i} />)}
      {halfStar && <StarHalfFilled />}
      {[...Array(emptyStars)].map((_, i) => <StarEmpty key={i} />)}
      <div className='total'>
        {offer.vendor_ratings_count > 0 && <a href={`/${I18n.locale}/users/${offer.user_id}/ratings`} className='text-decoration-underline mr-1' onClick={e => e.stopPropagation()}>{offer.vendor_ratings_count}</a>}
        {offer.vendor_ratings_count === 0 && <> {offer.vendor_ratings_count} </>}

        <span class="text-small text-faded fw-300">{I18n.t('frontend.offers_index.hits.ratings')}</span>
      </div>
    </div>
  </div>
}

export default OfferRating
