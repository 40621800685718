import React, { useRef, useEffect } from 'react'

const INPUT_CLASS_NAME = 'form-control shipping-item-quantity shipping-item-input'

function isValid(value, required = false) {
  if(required && value === '') return false

  const parsed = parseInt(value)

  if(isNaN(parsed)) return false
  if(parsed <= 0) return false

  return true
}

const MeasurementsInput = ({ item, update, required = true }) => {
  const el = useRef(null)
  const lengthInputEl = useRef(null)
  const widthInputEl = useRef(null)
  const heightInputEl = useRef(null)

  useEffect(() => {
    if(document.activeElement === lengthInputEl.current || document.activeElement === widthInputEl.current || document.activeElement === heightInputEl.current) {
      el.current.classList.add('input-combined--focussed')
    }
  }, [item.length.valid, item.width.valid, item.height.valid])

  const handleFocus = () => {
    el.current.classList.add('input-combined--focussed')
  }

  const handleBlur = () => {
    el.current.classList.remove('input-combined--focussed')
  }

  const handleChanged = (attribute, value) => {
    update(attribute, value, isValid(value, required))
  }

  const handleKeyDownLength = e => {
    const { selectionStart, selectionEnd, value } = e.target

    if(e.keyCode === 39 && selectionStart === value.length && selectionEnd === value.length) {
      e.preventDefault()
      widthInputEl.current.focus()
      return
    }
  }

  const handleKeyDownWidth = e => {
    const { selectionStart, selectionEnd, value } = e.target

    if(e.keyCode === 37 && selectionStart === 0 && selectionEnd === 0) {
      e.preventDefault()
      lengthInputEl.current.setSelectionRange(lengthInputEl.current.value.length, lengthInputEl.current.value.length)
      lengthInputEl.current.focus()
      return
    }

    if(e.keyCode === 39 && selectionStart === value.length && selectionEnd === value.length) {
      e.preventDefault()
      heightInputEl.current.focus()
      return
    }

    if(e.key === 'Backspace' && value === '') {
      e.preventDefault()
      lengthInputEl.current.setSelectionRange(lengthInputEl.current.value.length, lengthInputEl.current.value.length)
      lengthInputEl.current.focus()
    }
  }

  const handleKeyDownHeight = e => {
    const { selectionStart, selectionEnd, value } = e.target

    if(e.keyCode === 37 && selectionStart === 0 && selectionEnd === 0) {
      e.preventDefault()
      widthInputEl.current.setSelectionRange(widthInputEl.current.value.length, widthInputEl.current.value.length)
      widthInputEl.current.focus()
      return
    }

    if(e.key === 'Backspace' && value === '') {
      e.preventDefault()
      widthInputEl.current.setSelectionRange(widthInputEl.current.value.width, lengthInputEl.current.value.length)
      widthInputEl.current.focus()
    }
  }

  const handleLengthChanged = e => handleChanged('length', e.target.value)
  const handleWidthChanged = e => handleChanged('width', e.target.value)
  const handleHeightChanged = e => handleChanged('height', e.target.value)

  const klassName = (item.length.valid && item.width.valid && item.height.valid) ? 'input-combined' : `input-combined is-invalid`

  return <div className="form-group">
    <label>Length x Width x Height</label>
    <div className="input-group flex-nowrap">
      <div className={klassName} ref={el}>
        <input className={INPUT_CLASS_NAME} value={item.length.value} onChange={handleLengthChanged} name='length' onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDownLength} ref={lengthInputEl} />
        <div  className="spacer">x</div>
        <input className={INPUT_CLASS_NAME} value={item.width.value} onChange={handleWidthChanged} name='width' onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDownWidth}ref={widthInputEl} />
        <div className="spacer">x</div>
        <input className={INPUT_CLASS_NAME} value={item.height.value} onChange={handleHeightChanged} name='height' onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDownHeight} ref={heightInputEl} />
      </div>
      <div className="input-group-append">
        <div className="input-group-text">cm</div>
      </div>
    </div>
  </div>
}

export default MeasurementsInput
