import React from 'react'

import InputWithPrecision from './InputWithPrecision'

function isValid(value, required = false) {
  if(required && value === '' || value === undefined) return false

  const parsed = parseFloat(value)

  if(isNaN(parsed)) return false
  if(parsed <= 0) return false

  return true
}

const DecimalInput = ({ item, update, name, label, min = 1, unit, className, required = true, decimal = false }) => {
  const setValue = value => {
    const valid = isValid(value, required)

    update(name, value, valid)
  }

  return <InputWithPrecision label={label} unit={unit} value={item[name].value} setValue={setValue} name={name} min={min} className={className} valid={item[name].valid} />
}

export default DecimalInput
