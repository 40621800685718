import React, { useState, useRef } from 'react'

import { closeModal } from '../src/helpers'

import ProductInformation from './ShippingItemsIndex/ProductInformation'
import ShippingItem from './ShippingItemsIndex/ShippingItem'

export const context = React.createContext({})
const ContextProvider = context.Provider

function calculateTotals(items) {
  let totalQuantity = 0
  let totalPieces = 0
  let totalWeight = 0
  let totalVolume = 0

  for(const item of items) {
    if(item.quantity.value && item.quantity.valid)
      totalQuantity += parseInt(item.quantity.value)

    if(item.quantity.value && item.quantity.valid && item.weight.value && item.weight.valid)
      totalWeight += parseInt(item.quantity.value) * parseFloat(item.weight.value)

    if(item.quantity.value && item.quantity.valid && item.length.value && item.length.valid && item.width.value && item.width.valid && item.height.value && item.height.valid)
      totalVolume += (parseInt(item.quantity.value) * parseInt(item.length.value) * parseInt(item.width.value) * parseFloat(item.height.value))

    for(const shippingItemProduct of item.shipping_item_products) {
      const quantity = parseInt(item.quantity.value) || 0
      const pieces = parseInt(shippingItemProduct.quantity.value) || 0
      totalPieces += pieces * quantity
    }
  }

  if(totalVolume > 0) totalVolume = (totalVolume / 1000000.0).toFixed(2)
  if(totalWeight > 0) totalWeight = totalWeight.toFixed(2)

  return [totalQuantity, totalPieces, totalWeight, totalVolume]
}

function calculateRequiredPieces(products) {
  let requiredPieces = 0

  for(const product of products) {
    requiredPieces += product.quantity
  }

  return requiredPieces
}

function addItem(items, setItems, products, defaultSizes, lastInsertId) {
  const lastItem = items[items.length - 1]
  const newItem = {
    id: `new-${lastInsertId.current++}`,
  }

  if(lastItem) {
    newItem.quantity = { ...lastItem.quantity }
    newItem.package_type = { ...lastItem.package_type }
    newItem.length = { ...lastItem.length }
    newItem.width = { ...lastItem.width }
    newItem.height = { ...lastItem.height }
    newItem.weight = { ...lastItem.weight }
    newItem.stackable = { ...lastItem.stackable }

    for(const sip of lastItem.shipping_item_products) {
      newItem.shipping_item_products = [{
        inquiry_offer_id: sip.inquiry_offer_id,
        quantity: { ...sip.quantity },
      }]
    }

    setItems(state => [...state, newItem])
    return
  }

  const product = products[0]
  const pallet = defaultSizes['pallet']

  newItem.quantity = { value: 1, valid: true }
  newItem.package_type = { value: pallet.name, valid: true }
  newItem.length = { value: pallet.length, valid: true }
  newItem.width = { value: pallet.width, valid: true }
  newItem.height = { value: pallet.height, valid: true }
  newItem.weight = { value: pallet.weight, valid: true }
  newItem.stackable = { value: false, valid: true }

  if(product) {
    if(product.weight) newItem.weight.value += product.weight
    newItem.stackable = { value: product.stackable, valid: true }
    newItem.shipping_item_products = [{
      inquiry_offer_id: product.inquiry_offer_id,
      quantity: { value: 1, valid: true },
    }]
  }

  setItems(state => [...state, newItem])
  return
}

const ShippingContextProvider = ({ products, defaultSizes, setItems, lastInsertId, groupSeparator, decimalSeparator, children }) => {
  return <ContextProvider value={{products, defaultSizes, setItems, lastInsertId, groupSeparator, decimalSeparator}}>
    {children}
  </ContextProvider>
}

function initItems(shippingItems, products, lastInserId) {
  return shippingItems.map(shippingItem => {
    if(shippingItem.shipping_item_products.length > 0) return shippingItem

    return {
      ...shippingItem,
      shipping_item_products: [{
        id: `new-${lastInserId.current++}`,
        inquiry_offer_id: products[0].inquiry_offer_id,
        quantity: { value: 1, valid: true },
      }]
    }
  })
}

function isValid(items) {
  for(const item of items) {
    if(!item.quantity.valid || !item.package_type.valid || !item.length.valid || !item.width.valid || !item.height.valid || !item.weight.valid || !item.stackable.valid) return false

    for(const shippingItemProduct of item.shipping_item_products) {
      if(!shippingItemProduct.inquiry_offer_id) return false
      if(!shippingItemProduct.quantity.valid) return false
    }
  }

  return true
}

function prepareItemsForSubmit(items) {
  return items.map(item => {
    return {
      id: item.id,
      quantity: item.quantity.value,
      package_type: item.package_type.value,
      length: item.length.value,
      width: item.width.value,
      height: item.height.value,
      weight: item.weight.value,
      stackable: item.stackable.value,
      shipping_item_products: item.shipping_item_products.map(sip => {
        return {
          id: sip.id,
          inquiry_offer_id: sip.inquiry_offer_id,
          quantity: sip.quantity.value,
        }
      })
    }
  })
}

const ShippingItemsIndex = ({ shippingId, shippingItems = [], products, defaultSizes, groupSeparator, decimalSeparator }) => {
  const lastInsertId = useRef(0)
  const [ items, setItems ] = useState(() => initItems(shippingItems, products, lastInsertId))

  const [ totalQuantity, totalPieces, totalWeight, totalVolume ] = calculateTotals(items)
  const requiredPieces = calculateRequiredPieces(products)

  const handleAdd = e => {
    e.preventDefault()

    addItem(items, setItems, products, defaultSizes, lastInsertId)
  }

  const handleSave = async e => {
    e.preventDefault()

    if(!isValid(items)) return

    const preparedItems = prepareItemsForSubmit(items)
    const response = await fetch(`/api/v1/admin/shippings/${shippingId}/items`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({shipping_items: preparedItems}),
    })

    if(response.ok) {
      closeModal()
      Turbo.visit(window.location.href, {action: 'replace', frame: `shipping-${shippingId}`});
    } else {
      window.alert('An error occured :(')
    }
  }

  const handleCancel = e => {
    e.preventDefault()
    closeModal()
  }

  return <ShippingContextProvider products={products} defaultSizes={defaultSizes} setItems={setItems} lastInsertId={lastInsertId} groupSeparator={groupSeparator} decimalSeparator={decimalSeparator}>
    <div className=".card.card-modal.card-default.card-modal--large">
      <h5 className="card-header">Shipping items</h5>
      <div className="card-body">
        <ProductInformation items={items} />
      </div>
      {items.map(item => <div className="card-body" key={item.id}>
        <ShippingItem item={item} />
      </div>)}
      <div className="card-body">
        <button className="btn btn-primary" onClick={handleAdd}>Add</button>
      </div>
      <div className="card-body d-flex flex-row align-items-center">
        <div className="actions mr-4 text-nowrap">
          <div className="btn btn-light" onClick={handleCancel}>Cancel</div>
          <input type="submit" onClick={handleSave} className="btn btn-primary" value="Save" disabled={!isValid(items)} />
        </div>

        <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center">
          <div className="total-quantity font-weight-bold text-nowrap">
            Pack. items: <span>{totalQuantity}</span>
          </div>

          <div className="total-pieces font-weight-bold text-nowrap">
            Pieces: <span>{totalPieces} / {requiredPieces}</span>
          </div>

          <div className="total-vol font-weight-bold text-nowrap">
            Vol.: <span>{totalVolume}</span>
            m<sup>3</sup>
          </div>

          <div className="total-weight font-weight-bold text-nowrap">
            Weight: <span>{totalWeight}</span>
            kg
          </div>
        </div>
      </div>
    </div>
  </ShippingContextProvider>
}

export default ShippingItemsIndex
