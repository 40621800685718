import React, { useMemo } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import aa from 'search-insights'
import isbot from 'isbot'

import I18n from '@/src/i18n.js.erb'

import useAlgoliaClient from './hooks/useAlgoliaClient'
import useUrlSearchState from './hooks/useUrlSearchState'

import SearchBox from './OffersIndex/SearchBox'
import Filters from './OffersIndex/Filters'
import Hits from './OffersIndex/Hits'

import SaveSearchButton from './OffersIndex/SaveSearchButton'
import SearchResultSerializer from './OffersIndex/SearchResultSerializer'
import SearchContextProvider from './OffersIndex/SearchContextProvider'
import SortOnFilter from './OffersIndex/SortOnFilter'
import QuantityFilter from './OffersIndex/QuantityFilter'
import { ExpandAllIcon, CollapseAllIcon, ArrowUp } from './OffersIndex/Icons';

const OffersIndex = ({ appId, apiKey, userToken, fixedCategory = null, producer, defaultIndex, indexOnFilter, rootCategories, categorySlugs = {}, userStatus = null, emailConfirmed = false, countryCode = null, userId, vendorId = null, basePath = '/products', inquiryId = null, initialState = null, favoritesUuids = [], singleVendorId = null }) => {
  const searchClient = useAlgoliaClient(appId, apiKey)
  const [state, setState] = useUrlSearchState(basePath, categorySlugs, initialState)
  const [offersExpanded, setOffersExpanded] = React.useState(false);

  const isLoggedIn = !!userStatus
  // const userRegistrationCompleted = userStatus && emailConfirmed
  const attributesToRetrieve = ['*', '-offer.region_blacklist']
  const botSpecificCssClass = isbot(navigator.userAgent) ? "d-none" : ""

  aa('init', { appId, apiKey })
  aa('setUserToken', userToken)

  const filters = useMemo(() => {
    const f = []

    if(singleVendorId) f.push('offer.user_id:' + singleVendorId)

    f.push('offer.published_until_ts >= ' + Math.floor(new Date / 1000))

    if (countryCode && vendorId) {
      f.push('offer.user_id:' + vendorId + ' AND NOT offer.region_blacklist:' + countryCode)
    } else if (countryCode && userId) {
      f.push('offer.user_id:' + userId + ' OR NOT offer.region_blacklist:' + countryCode)
    }

    if(producer) f.push('producer_name:"' + producer + '"')

    if(state.pt1) {
      const pt = parseInt(state.pt1)
      if(!isNaN(pt)) f.push('pt1 <=' + pt)
    }

    if(state.pt2) {
      const pt = parseInt(state.pt2)
      if(!isNaN(pt)) f.push('pt2 <=' + pt)
    }

    if(state.pt3) {
      const pt = parseInt(state.pt3)
      if(!isNaN(pt)) f.push('pt3 <=' + pt)
    }

    return f.join(' AND ')
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return <InstantSearch searchClient={searchClient} indexName={defaultIndex} searchState={state} onSearchStateChange={setState}>
    <Configure hitsPerPage={10} clickAnalytics attributesToRetrieve={attributesToRetrieve} filters={filters} distinct />
    <SortOnFilter defaultIndex={defaultIndex} indexOnFilter={indexOnFilter} query={state.query} />

    <SearchResultSerializer searchClient={searchClient} />

    <SearchContextProvider searchState={state} userId={userId} vendorId={vendorId} inquiryId={inquiryId} userStatus={userStatus} emailConfirmed={emailConfirmed} categorySlugs={categorySlugs} countryCode={countryCode} offersExpanded={offersExpanded} favoritesUuids={favoritesUuids}>
      <div className="row">
        <div className={`col-md-3 ${botSpecificCssClass}`}>
          <Filters fixedCategory={fixedCategory} producer={producer} rootCategories={rootCategories} />
          {!userStatus && <div className='register-cta mt-3 mb-4'>
            {I18n.t('frontend.offers_index.cta.title')}<br />
            <a href={'/' + I18n.locale + '/signup'} className='btn btn-lg btn-primary btn-block mt-3'>{I18n.t('frontend.offers_index.cta.button')}</a>
          </div>}

          <div class="mt-2 mb-4">
            <SaveSearchButton className="btn-block" />
          </div>
        </div>
        <div className='col-md-9 d-flex flex-column flex-gap-4'>
          <div className={`position-sticky search-bar-sticky ${botSpecificCssClass}`}>
            <div className='d-flex flex-gap-3 marketplace__search-bar'>
              <div className='w-100'>
                <SearchBox placeholders={I18n.t('frontend.offers_index.searchbar.placeholders')} />
              </div>

              <div className='d-flex align-items-center p-0 flex-gap-3'>
                <div className='w-100'>
                  <QuantityFilter />
                </div>

                <span className='clickable' onClick={() => setOffersExpanded(!offersExpanded)}>
                  {!offersExpanded && <ExpandAllIcon />}
                  {offersExpanded && <CollapseAllIcon />}
                </span>
              </div>
            </div>
          </div>
          <div className='position-relative w-full d-flex justify-content-end'>
            <div onClick={scrollToTop} className='marketplace__scroll-top'>
              <ArrowUp />
            </div>
          </div>

          <Hits
            index={state.sortyBy || defaultIndex}
            // userRegistrationCompleted={userRegistrationCompleted}
            inquiryId={inquiryId}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </div>
    </SearchContextProvider>
  </InstantSearch>
}

export default OffersIndex
