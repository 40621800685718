import React, { useContext } from 'react'

import { context } from '../ShippingItemsIndex'

import Select from './Select'

const ProductSelect = ({ item, shippingItemProduct, label }) => {
  const { products, defaultSizes, setItems } = useContext(context)

  const name = 'inquiry_offer_id'
  const productOptions = []

  for(const product of products) {
    productOptions.push({ value: product.inquiry_offer_id, label: product.name })
  }

  const handleChange = e => {
    let newWeight = 0

    for(const sip of item.shipping_item_products) {
      if(sip.id !== shippingItemProduct.id) {
        const product = products.find(p => p.inquiry_offer_id === sip.inquiry_offer_id)

        if(product) {
          const pieces = sip.quantity.value || 0
          const weight = product.weight || 0

          newWeight += (weight * pieces)
        }
      }
    }

    const inquiryOfferId = parseInt(e.target.value)
    const product = products.find(p => p.inquiry_offer_id === inquiryOfferId)
    const packageType = defaultSizes[item.package_type.value]

    const pieces = shippingItemProduct.quantity.value || 0
    const weight = product.weight || 0

    if(product.weight) newWeight += (weight * pieces)
    if(packageType.weight) newWeight += packageType.weight

    setItems(state => {
      return state.map(it => {
        if(it.id !== item.id) return it

        return {
          ...it,
          weight: { value: newWeight, valid: true },
          shipping_item_products: it.shipping_item_products.map(sip => {
            if(sip.id !== shippingItemProduct.id) return sip
            return { ...sip, inquiry_offer_id: inquiryOfferId }
          })
        }
      })
    })
  }

  return <Select name={name} value={shippingItemProduct[name]} onChange={handleChange} label={label} options={productOptions} />
}

export default ProductSelect
