import React, { useContext } from 'react'

import XSquareIcon from '../common/XSquareIcon'

import { context } from '../ShippingItemsIndex'

import PiecesInput from './PiecesInput'
import ProductSelect from './ProductSelect'

const ShippingItemProduct = ({ item, shippingItemProduct }) => {
  const { setItems, products } = useContext(context)

  const handleRemove = e => {
    e.preventDefault()

    setItems(state => {
      return state.map(it => {
        if(it.id !== item.id) return it
        if(it.shipping_item_products.length <= 1) return it

        const itemToRemove = it.shipping_item_products.find(sip => sip.id === shippingItemProduct.id)
        let weightToRemove = 0

        if(itemToRemove) {
          const product = products.find(p => p.inquiry_offer_id === itemToRemove.inquiry_offer_id)

          if(product) {
            const pieces = itemToRemove.quantity.value || 0
            const weight = product.weight || 0
            weightToRemove = (weight * pieces)
          }
        }

        return {
          ...it,
          weight: { value: it.weight.value - weightToRemove, valid: true },
          shipping_item_products: it.shipping_item_products.filter(sip => sip.id !== shippingItemProduct.id)
        }
      })
    })
  }

  return <div className="row shipping-item-products__item">
    <div className="col col-md-3">
      <PiecesInput item={item} shippingItemProduct={shippingItemProduct} />
    </div>

    <div className="col col-md-8">
      <ProductSelect item={item} shippingItemProduct={shippingItemProduct} />
    </div>

    <div className="col col-md-1 shipping-item-products__item-actions">
      <XSquareIcon onClick={handleRemove} width='20' />
    </div>
  </div>
}

export default ShippingItemProduct
