import React from 'react'

import ProducerLogo from '../common/ProducerLogo'
import ProductImage from '../common/ProductImage'

import Title from '../OffersIndex/Title'
import ProductInfo from '../OffersIndex/ProductInfo'
import I18n from '../../src/i18n.js.erb'
import { FavoriteIconRemove } from '../OffersIndex/Icons'

import { PRODUCT_TYPES } from '../../src/product_types'

const activeOfferDescription = (hit) => {
  if (hit.nr_of_active_offers && hit.nr_of_offered_pieces) {
    return `${hit.nr_of_active_offers} (${hit.nr_of_offered_pieces} ${I18n.t('frontend.general.pcs')})`
  } else if (hit.nr_of_active_offers) {
    return hit.nr_of_active_offers
  } else {
    return "-"
  }
}

const Card = ({ hit, productUrl, onClick, ...props }) => {
  const [isFavorited, setIsFavorited] = React.useState(true);
  const [isAnimated, setIsAnimated] = React.useState(null);

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
    setIsAnimated(true);
  }

  return <>
    {isFavorited && <div className='card mb-3' {...props}>
      <div className='card__product card__product-rounded-bottom' onClick={onClick}>
        <div className='card__product-image'>
          <ProductImage hit={hit} width={130} height={150} />
        </div>
        <div className='card__product-info'>

          <div className='card__product-info-title mb-0 d-none d-md-block'>
            <div className='d-flex align-items-center' >
              <Title hit={hit} productUrl={productUrl} />

              <a rel="nofollow" onClick={() => toggleFavorite()} data-turbo="true" data-method="delete" href={`/${I18n.locale}/favorites?favoritable_id=${hit.id}&favoritable_type=${PRODUCT_TYPES[hit.type]}`}>
                <FavoriteIconRemove classes={isAnimated ? 'icon-animate-pop' : ''} />
              </a>
            </div>
            <div className='card__product-info-title-producer d-flex flex-gap-3' >
              <ProducerLogo hit={hit} width={60} height={22} />
              {hit.individual_product_name_ext != null && <>{hit.individual_product_name_ext}</>}

              {hit?.type == 'battery' && hit?.category_name != 'batteries' &&
                <span data-toggle='tooltip' data-placement='top' data-original-title={I18n.t('frontend.offers_index.hits.battery_category_tooltips.' + hit.category_name)} className='has-tooltip'>{I18n.t('frontend.general.categories.' + hit.category_name)}</span>
              }
            </div>
          </div>

          <div className='card__product-info-details-container rounded p-3 mt-1'>
            <ProductInfo hit={hit} />
          </div>

          <div className='card__product-info-offers-container rounded p-3 mt-1'>
            <span className='d-none d-md-inline'>{I18n.t('frontend.offers_index.hits.active_offers')} {activeOfferDescription(hit)}</span>
          </div>
        </div>
      </div>
    </div>}
  </>
}

export default Card
