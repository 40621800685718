import React from 'react'

const COLORS = {
  1: 'silversilver',
  2: 'blacksilver',
  3: 'blackblack'
}

const urlForType = {
  solar_module: '/en/admin/solar_modules/',
  inverter:     '/en/admin/inverters/',
  battery:      '/en/admin/batteries/',
  accessory:    '/en/admin/accessories/',
  e_mobility:   '/en/admin/e_mobility_products/'
}

const ModuleColorIcon = ({hit}) => {
  if(hit.color && COLORS[hit.color]) {
    return <div className={'product-solar-module-color product-solar-module-color--' + COLORS[hit.color]} />
  }

  return null
}

const DangerousGoodsStatus = ({hit}) => {
  if(hit.dangerous_goods_status === 'ok') return <div className={'product-battery-dangerous-goods-status product-battery-dangerous-goods-status--ok'} />
  if(hit.dangerous_goods_status === 'incomplete') return <div className={'product-battery-dangerous-goods-status product-battery-dangerous-goods-status--incomplete'} />

  return null
}

const SolarModule = ({hit}) => <>
  {hit.single_power && <>{hit.single_power}W </>}
  {hit.cell_type_name && <>{hit.cell_type_name} </>}
  <ModuleColorIcon hit={hit} />
</>

const Inverter = ({hit}) => <>
  {hit.pnom_dc && <>{hit.pnom_dc} KW </>}
  {hit.no_of_phases && <>{hit.no_of_phases} Phase</>}
</>

const Battery = ({hit}) => <>
  {hit.capacity && <>{hit.capacity} KWh Capacity </>}
</>

const Hit = ({hit, typeFilter}) => {
  const clicked = () => {
    open(urlForType[hit.type] + hit.id + '/edit')
  }

  let details = null

  switch(hit.type) {
    case 'solar_module': details = <SolarModule hit={hit} />; break
    case 'inverter':     details = <Inverter hit={hit} />; break
    case 'battery':      details = <Battery hit={hit} />; break
  }

  return <tr onClick={clicked} className='clickable'>
    <td className='name'>{hit.name}</td>
    <td className='name'>{hit.series_name} {hit.series_name2 && <>/ {hit.series_name2}</>}</td>
    <td className='details'>{details}</td>
    {typeFilter === 'battery' && <td><DangerousGoodsStatus hit={hit} /></td>}
    <td className='product-no'>{hit.product_no_kra}</td>
    <td className='product-no'>{hit.product_no_ibc}</td>
    <td className='product-no'>{hit.product_no_seg}</td>
    <td className='product-no'>{hit.product_no_bay}</td>
    <td className='product-no'>{hit.product_no_gra}</td>
    <td className='product-no'>{hit.product_no_libra}</td>
    <td className='product-no'>{hit.product_no_gcl}</td>
    <td className='check'>{!hit.no_uploads && <div className='product-green-check' />}</td>
    <td className='check'>{!hit.no_description && <div className='product-green-check' />}</td>
    <td className='check'>{!hit.no_image && <div className='product-green-check' />}</td>
  </tr>
}

export default Hit
